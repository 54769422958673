<template>
  <div class="page-user-noticelist">
    <div class="my-page">
      <div class="list-box">
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="getDataList"
            offset="10"
          >
            <div
              class="my-card"
              v-for="(item, i) in dataList"
              :key="i"
              @click="handleClick(item)"
            >
              <div class="card-main">
                <div class="card_right">
                  <p class="title">{{ item.GroupAuidtTypeName }}:{{item.Title}}</p>
                  <p class="desc">{{ item.Desc }}</p>
                </div>
                <div class="card_left">
                  <img
                    :src="item.TitleImage ? item.TitleImage : defaultImg"
                    alt=""
                  />
                </div>
              </div>
              <div class="card_bottom">
                <span class="time">{{ item.CreateDateStr }}</span>

                <div v-if="item.AuditStateName === '未审核'" class="unaudit">
                  待审批
                </div>
                <div v-if="item.AuditStateName === '通过'" class="audit">
                  通过
                </div>
                <div v-if="item.AuditStateName === '驳回'" class="reject">
                  驳回
                </div>
              </div>
            </div>
          </van-list>
        </van-pull-refresh>
      </div>
      <!-- <van-button type="primary" block color="#ffe500" @click="add()">添加记录</van-button> -->
    </div>
  </div>
</template>
  
  <script>
import { Button, List, PullRefresh } from "vant";
import defaultImg from "@/assets/img/defaultImg.png";
export default {
  components: {
    [Button.name]: Button,
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
  },
  data() {
    return {
      defaultImg: defaultImg,
      loading: false,
      finished: false, // false
      refreshing: false,
      dataList: [],
      page: 0, // 页码
      limit: 10, // 每页条数
      moduleId: null,
    };
  },
  mounted() {
    this.moduleId = this.$route.query.moduleId;
   // this.getDataList();
  },
  methods: {
    add() {
      this.$toast("功能正在开发中...");
    },
    getDataList() {
      this.page++;
      this.getList();
    },
    getList() {
      const that = this;
      that.$axios
        .get("/api/CloudClassroom/cloudClassroom-groupList", {
          page: that.page,
          limit: that.limit,
          moduleId: that.moduleId,
        })
        .then((res) => {
          if (res.code === 200) {
            that.refreshing = false;
            that.loading = false;
            that.dataList = that.dataList.concat(res.data);
            if (that.dataList.length >= res.count) {
              that.finished = true;
            }
          } else {
            that.$toast.fail(res.msg || "操作失败");
          }
        });
    },
    // 下拉刷新
    onRefresh() {
      this.page = 0;
      // 清空列表数据
      this.finished = false;
      // 将 loading 设置为 true，表示处于加载状态
      // this.loading = true
      this.dataList = [];
      this.getDataList();
    },
    handleClick(item) {
      this.$router.push({
        path: "ContentView",
        query: {
          id: item.Id,
          auditID: item.AuditId,
          type: item.GroupAuidtType,
        },
      });
    },
  },
};
</script>
  <style lang="less" scoped>
@import "./index.less";
</style>
  