<template>
  <div class="page-folder">
    <div class="my-page">
      <div class="list-box">
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="getDataList"
            offset="10"
          >
            <div
              class="my-card"
              v-for="(item, i) in dataList"
              :key="i"
              @click="handleClick(item)"
            >
              <p class="p">
                {{
                  item.ChildrenName ? item.ChildrenName : item.CreateUserName
                }}的请假
              </p>
              <p class="time">{{ item.CreateDate }}</p>

              <div class="content-box">
                <div>
                  请假类型：<span>{{ item.LeaveTypeName }}</span>
                </div>
                <div>
                  开始时间：<span>{{ item.BeginDate }}</span>
                </div>
                <div>
                  结束时间：<span>{{ item.EndDate }}</span>
                </div>
              </div>
              <div class="card_left">
                <img
                  :src="item.ProfilePicture ? item.ProfilePicture : defaultImg"
                  alt=""
                />

                <span class="user">{{ item.CreateUserName }}提交的请假</span>
                <template>
                  <div v-if="item.AuditStateName === '未审核'" class="unaudit">
                    待审批
                  </div>
                  <div v-if="item.AuditStateName === '通过'" class="audit">
                    通过
                  </div>
                  <div v-if="item.AuditStateName === '驳回'" class="reject">
                    驳回
                  </div>
                </template>
              </div>
            </div>
          </van-list>
        </van-pull-refresh>
      </div>
    </div>
  </div>
</template>
  
  <script>
import { Button, List, PullRefresh } from "vant";
export default {
  components: {
    [Button.name]: Button,
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
  },
  data() {
    return {
      userType: window.localStorage.getItem("UserType"),
      loading: false,
      finished: false, // false
      refreshing: false,
      dataList: [],
      page: 0, // 页码
      limit: 10, // 每页条数
      imgShow: false,
      images: [],
    };
  },
  mounted() {
    // 空白时候自动触发
    // this.getDataList()
  },
  methods: {
    getDataList() {
      this.page++;
      this.getList();
    },
    getList() {
      const that = this;
      that.$axios
        .get("/api/LeaveForChildren/AuditListForTeacher", {
          page: this.page, // 页码
          limit: this.limit, // 每页条数
        })
        .then((res) => {
          if (res.code === 200) {
            that.refreshing = false;
            that.loading = false;
            that.dataList = that.dataList.concat(res.data);
            if (that.dataList.length >= res.count) {
              that.finished = true;
            }
          } else {
            that.$toast.fail(res.msg || "操作失败");
          }
        });
    },
    // 下拉刷新
    onRefresh() {
      this.page = 0;
      // 清空列表数据
      this.finished = false;
      // 将 loading 设置为 true，表示处于加载状态
      // this.loading = true
      this.dataList = [];
      this.getDataList();
    },
    handleClick(item) {
      // 抄送人只能查看
      if (item.LeaveAuditType === 1) {
        this.$router.push({
          path: "LeaveChildrenView",
          query: {
            id: item.LeaveID,
           
          },
        });
      }
      else{
        this.$router.push({
          path: "LeaveTeacherView",
          query: {
            id: item.LeaveID,
            auditID:item.AuditID,
          },
        });
      }
    },
  },
};
</script>
  <style lang="less" scoped>
@import "./index.less";
</style>
  