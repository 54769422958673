<template>
  <div class="page-auditschedule">
    <div v-if="isAdministration == 'false' && Principal == 'true'">
      <div class="tab-box">
        <div
          class="tab-item"
          v-for="(num, index) in tabs"
          :key="index"
          :class="{ active: index === tabNum }"
          @click="tabClick(index)"
        >
          {{ num }}
        </div>
      </div>
      <leaveChildren v-if="tabNum === 0" />
      <OverTime v-if="tabNum === 1" />
      <!-- <ChildrenInfo v-if="tabNum === 2" /> -->
      <proclamation v-if="tabNum === 2" />
      <Content v-if="tabNum === 3" />
      
    </div>
    <div v-if="isAdministration == 'false' && Principal == 'false'">
      <!-- <div class="tab-box">
        <div
          class="tab-item"
          v-for="(num, index) in tabs"
          :key="index"
          :class="{ active: index === tabNum }"
          @click="tabClick(index)"
        >
          {{ num }}
        </div>
      </div> -->
      <div class="tab_audit">
        <div class="audit_item" @click="tabClick(0)" :class="{ active: 0 === tabNum }">宝贝请假审核</div>
        <div class="audit_item" @click="tabClick(1)" :class="{ active: 1 === tabNum }">宝贝注册审核</div>
      </div>
      <ChildrenLeave v-if="tabNum === 0" />
      <ChildrenInfo v-if="tabNum === 1" />
    </div>
    <div v-if="isAdministration == 'true'">
      <div class="tab-box">
        <div
          class="tab-item"
          @click="tabClick(0)"
          :class="{ active: 0 === tabNum }"
        >
          内容审核
        </div>
        <div
          class="tab-item"
          @click="tabClick(1)"
          :class="{ active: 1 === tabNum }"
        >
          请假审核
        </div>
      </div>
      <Content v-if="tabNum === 0" />
      <LeaveChildren v-if="tabNum === 1" />
    </div>
  </div>
</template>

<script>
import { Icon, Image, ImagePreview} from "vant";
import LeaveChildren from "./LeaveChildren/List";
import Proclamation from "./Proclamation/List";
import Content from "./Content/List";
import ChildrenInfo from "./ChildrenInfo/List";
import OverTime from "./OverTime/List";
import ChildrenLeave from "../LeaveTeacher/ChildrenLeave/index.vue"

export default {
  components: {
    LeaveChildren,
    Proclamation,
    Content,
    ChildrenInfo,
    OverTime,
    ChildrenLeave,
  },
  data() {
    return {
      tabs: window.localStorage.getItem('Principal') == 'true'? ["请假审核","加班审核", "公告审核", "信息发布审核"]:["宝贝请假审核",'宝贝注册审核'],
      tabNum: 0,
      isAdministration: window.localStorage.getItem("IsAdministration"),
      Principal: window.localStorage.getItem('Principal')
    };
  },
  mounted() {
    // this.getUser()
    if (this.$route.query.active) {
      this.tabNum = Number(this.$route.query.active);
    }
  },
  methods: {
    tabClick(index) {
      this.tabNum = index;
    }
  }
};
</script>
<style lang="less" scoped>
.tab-box .tab-item{
  margin-right: 0.4rem;
}
.tab-box{
  white-space: nowrap;/*文本不会换行，文本会在在同一行上继续*/
    overflow-y:auto;/*可滑动*/
}
@import "./index.less";
</style>
